body, html, #root {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100dvh;
}

.App {
  box-sizing: border-box;
  text-align: center;
  background-color: #121212;
  width: 100vw;
  height: 100vh;
  display: block;
  color: white;

  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

