.game {
	width: 100vw;
	min-height: calc(100vh - 60px);
	height: fit-content;
	display: flex;
	align-items: center;
	padding: 50px 0;
	flex-direction: column;
	background-color: #121212;
	overflow-y: scroll;
}

.board {
	max-width: 450px;
	width: 100%;
	max-height: 500px;
	min-height: 250px;
	height: 35vh;
	border: 1px solid black;
	display: flex;
	flex-direction: column;
}

.row {
	flex: 33%;
	display: flex;
	flex-direction: row;
	margin: 5px;
}

.letter {
	flex: 33%;
	height: 100%;
	border: 1px solid grey;
	margin: 5px;
	display: grid;
	place-items: center;
	font-size: 30px;
	font-weight: bolder;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
}

#correct {
	background-color: #528d4e;
}

#almost {
	background-color: #b49f39;
}

#error {
	background-color: #3a393c;
}

.keyboard {
	max-width: 700px;
	width: 100%;
	margin-top: 60px;
}

.line1 {
	flex: 33%;
	display: flex;
	flex-direction: row;
	display: flex;
	justify-content: center;
	margin: 5px;
	gap: 5px;
}
.line2 {
	flex: 33%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 5px;
	gap: 5px;
}
.line3 {
	flex: 33%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 5px;
	gap: 5px;
}

.key {
	width: 50px;
	height: 70px;
	border-radius: 4px;
	display: grid;
	place-items: center;
	font-size: 20px;
	background-color: grey;
	color: white;
	font-family: Arial, Helvetica, sans-serif;
	cursor: pointer;
}

#big {
	width: 100px;
}
#disabled {
	background-color: #3a393c;
}

.gameOver {
	padding-top: 50px;
}

@media (max-width: 600px) {
	.keyboard {
		max-height: 200px;
	}
	.key {
		width: 40px;
		height: 50px;
		font-size: 15px;
	}
	#big {
		width: 80px;
	}

	.gameOver h3{
		font-size: 24px;
	}
}

@media (max-width: 400px) {
	.keyboard {
		max-height: 150px;
	}
	.key {
		width: 30px;
		height: 40px;
		font-size: 12px;
	}
	#big {
		width: 60px;
	}

	.gameOver h3{
		font-size: 20px;
	}
}
