.leaderboard {
    box-sizing: border-box;
    width: 100vw;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    overflow-y: auto;
}

.leaderboard h1 {
	margin: 20px;
	font-family: Helvetica, Arial, sans-serif;
	color: white;
	font-size: 45px;
}
.leaderboard-table {
    width: 90%;
    margin-top: 40px;
    border-collapse: collapse;
    overflow: hidden;
    text-align: center;
}

.leaderboard-table img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 10px;
}
.leaderboard-table th {
    background-color: #f2f2f2;
    color: #333;
    font-size: 20px;
    font-family: Helvetica, Arial, sans-serif;
}

.leaderboard th, td {
    padding: 10px;
    text-overflow: ellipsis;
    max-width: 100px;
    font-size: 20px;
}

@media (max-width: 768px) {
    .leaderboard-table {
        width: 95%;
    }
    .leaderboard th, td {
        padding: 5px;
        font-size: 15px;
    }
    .leaderboard h1 {
        font-size: 30px;
    }
}

@media (max-width: 500px) {
    .leaderboard-table {
        width: 90%;
    }
    .leaderboard th, td {
        padding: 5px;
        font-size: 15px;
    }
    .leaderboard h1 {
        font-size: 25px;
    }
}