nav {
	box-sizing: border-box;
	height: 60px;
	width: 100vw;
	margin: 0;
	border-bottom: 1px solid grey;
	
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0 50px;

	gap: 60px;
}

nav a {
	text-decoration: none;
	color: white;
}

nav h1 {
	margin: 0;
	font-family: Helvetica, Arial, sans-serif;
	color: white;
	font-size: 45px;
}

.login-btn {
	background-color: #ffffff;
	border: 2px solid #ffffff;
	color: black;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	cursor: pointer;
	padding: 10px 20px;

	border-radius: 2px;
}

.login-btn:hover {
	background-color: #000;
	color: #ffffff;
}

.avatar {
	width: 45px;
	height: 45px;
	border-radius: 50%;

	cursor: pointer;
}

.how-to-play-link {
	margin: 0;
	font-family: Helvetica, Arial, sans-serif;
	color: white;
	font-size: 20px;
	text-decoration: underline;
}

.spacer {
	flex-grow: 1;
	display: block;
}

@media (max-width: 768px) {
	nav {
		padding: 0 20px;
		gap: 20px;
	}

	nav h1 {
		font-size: 30px;
	}

	.login-btn {
		font-size: 14px;
		padding: 8px 16px;
	}

	.how-to-play-link {
		font-size: 16px;
	}

	nav .spacer {
		display: none;
	}
}

@media (max-width: 350px) {
	nav {
		padding: 0 10px;
		gap: 20px;
	}

	nav h1 {
		font-size: 25px;
	}

	.login-btn {
		font-size: 12px;
		padding: 6px 12px;
	}

	.how-to-play-link {
		font-size: 14px;
	}

	nav .spacer {
		display: none;
	}
}